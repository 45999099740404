// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;
}
.quiz-form-wrapper > * {
  width: 100%;
}
.quiz-form-wrapper .number-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.quiz-form-wrapper .number-controls > .form-group {
  width: 100%;
  margin: 0;
}
.quiz-form-wrapper .quiz-create-subforms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.quiz-form-wrapper .quiz-create-subforms .quiz-subform-wrapper {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 1rem;
}
.quiz-form-wrapper .quiz-create-subforms .quiz-subform-wrapper .quiz-subform-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quiz-form-wrapper .quiz-create-subforms .quiz-subform-wrapper .quiz-subform {
  margin-top: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/quiz/quiz-create/QuizCreate.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAAJ;AAEI;EACE,WAAA;EACA,SAAA;AAAN;AAIE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAFJ;AAII;EACE,+GAAA;EAGA,WAAA;EACA,aAAA;AAJN;AAMM;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAJR;AAOM;EACE,kBAAA;AALR","sourcesContent":[".quiz-form-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 5vh;\n\n  & > * {\n    width: 100%;\n  }\n\n  .number-controls {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 1rem;\n\n    & > .form-group {\n      width: 100%;\n      margin: 0;\n    }\n  }\n\n  .quiz-create-subforms {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 1rem;\n\n    .quiz-subform-wrapper {\n      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),\n        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);\n\n      width: 100%;\n      padding: 1rem;\n\n      .quiz-subform-title-wrapper {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n      }\n\n      .quiz-subform {\n        margin-top: 1.5rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
