// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-create-btn {
  margin-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/courses/courses-single/courses-subsection-create/CoursesSubsectionCreate.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":[".sub-create-btn {\n  margin-top: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
