// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-img img {
  width: 100%;
}

.posts-list {
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.blog-like-btn {
  cursor: pointer;
}
.blog-like-btn.liked, .blog-like-btn:hover {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/single-blog/SingleBlog.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,0BAAA;EACA,yBAAA;AACF;;AAEA;EACE,eAAA;AACF;AACE;EAEE,UAAA;AAAJ","sourcesContent":[".feature-img img {\n  width: 100%;\n}\n\n.posts-list {\n  max-width: 100% !important;\n  flex: 0 0 100% !important;\n}\n\n.blog-like-btn {\n  cursor: pointer;\n\n  &.liked,\n  &:hover {\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
