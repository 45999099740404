// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-pagination {
  margin-top: 80px;
}

.blog-pagination .page-link {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 0;
  text-align: center;
  margin-left: -1px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 0 !important;
  color: #8a8a8a;
  border: 1px solid #f0e9ff;
  margin-right: 10px;
}
.blog-pagination .page-link i,
.blog-pagination .page-link span {
  font-size: 13px;
}
.blog-pagination .page-item.active .page-link {
  background-color: #fbf9ff;
  border-color: #f0e9ff;
  color: #888888;
}

.blog-pagination .page-item:last-child .page-link {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/blog-left-sidebar/blog-pagination/BlogPagination.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,cAAA;EACA,UAAA;EACA,kBAAA;EAEA,iBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;AAAF;AAEE;;EAEE,eAAA;AAAJ;AAUE;EACE,yBAAA;EACA,qBAAA;EACA,cAAA;AARJ;;AAYA;EACE,eAAA;AATF","sourcesContent":[".blog-pagination {\n  margin-top: 80px;\n}\n\n.blog-pagination .page-link {\n  font-size: 14px;\n  position: relative;\n  display: block;\n  padding: 0;\n  text-align: center;\n  // padding: 0.5rem 0.75rem;\n  margin-left: -1px;\n  line-height: 45px;\n  width: 45px;\n  height: 45px;\n  border-radius: 0 !important;\n  color: #8a8a8a;\n  border: 1px solid #f0e9ff;\n  margin-right: 10px;\n\n  i,\n  span {\n    font-size: 13px;\n  }\n\n  &:hover {\n    // background-color: $baseColor;\n    // color: $white_color;\n  }\n}\n\n.blog-pagination .page-item.active {\n  .page-link {\n    background-color: #fbf9ff;\n    border-color: #f0e9ff;\n    color: #888888;\n  }\n}\n\n.blog-pagination .page-item:last-child .page-link {\n  margin-right: 0;\n}\n\n// .blog-pagination .page-link .lnr {\n//     font-weight: 600;\n// }\n\n// .blog-pagination .page-item:last-child .page-link,\n// .blog-pagination .page-item:first-child .page-link {\n//     border-radius: 0;\n// }\n\n// .blog-pagination .page-link:hover {\n//     color: #fff;\n//     text-decoration: none;\n//     background-// color: $title-color;\n//     border-color: #eee;\n// }\n\n// .blog-pagination .page-item.active .page-link {\n//     background-// color: $title-color;\n//     border-color: transparent;\n//     color:#fff;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
