// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.res-create-btn {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/courses/courses-single/courses-resources-create/CoursesResourcesCreate.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,+BAAA;AACF","sourcesContent":[".res-create-btn {\n  padding-top: 10px !important;\n  padding-bottom: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
