// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog_right_sidebar div.media.post_item {
  align-items: center;
  justify-content: center;
}
.blog_right_sidebar div.media.post_item img {
  width: 100px;
  height: 50px;
}
.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
}
.blog_right_sidebar .widget_title::after {
  content: "";
  display: block;
  padding-top: 15px;
  border-bottom: 1px solid #f0e9ff;
}
.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px;
}
.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: rgb(238, 238, 238);
  margin: 30px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/blog-right-sidebar/BlogRightSidebar.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,uBAAA;AAAJ;AAEI;EACE,YAAA;EACA,YAAA;AAAN;AAIE;EACE,eAAA;EACA,mBAAA;AAFJ;AAII;EACE,WAAA;EACA,cAAA;EACA,iBAAA;EACA,gCAAA;AAFN;AAME;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;AAJJ;AAOE;EACE,WAAA;EACA,WAAA;EACA,8BAAA;EACA,gBAAA;AALJ","sourcesContent":[".blog_right_sidebar {\n  div.media.post_item {\n    align-items: center;\n    justify-content: center;\n\n    img {\n      width: 100px;\n      height: 50px;\n    }\n  }\n\n  .widget_title {\n    font-size: 20px;\n    margin-bottom: 40px;\n\n    &::after {\n      content: '';\n      display: block;\n      padding-top: 15px;\n      border-bottom: 1px solid #f0e9ff;\n    }\n  }\n\n  .single_sidebar_widget {\n    background: #fbf9ff;\n    padding: 30px;\n    margin-bottom: 30px;\n  }\n\n  .br {\n    width: 100%;\n    height: 1px;\n    background: rgb(238, 238, 238);\n    margin: 30px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
