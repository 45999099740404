// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-simple.alert-danger {
  border: 1px solid rgba(241, 6, 6, 0.81);
  background-color: rgba(220, 17, 1, 0.16);
  box-shadow: 0px 0px 2px #ff0303;
  color: #ff0303;
  transition: 0.5s;
  cursor: pointer;
}

.alert-danger:hover {
  background-color: rgba(220, 17, 1, 0.33);
  transition: 0.5s;
}

.danger {
  font-size: 18px;
  color: #ff0303;
}`, "",{"version":3,"sources":["webpack://./src/components/common/alerts/error-alert/ErrorAlert.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,wCAAA;EACA,+BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,wCAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF","sourcesContent":[".alert-simple.alert-danger {\n  border: 1px solid rgba(241, 6, 6, 0.81);\n  background-color: rgba(220, 17, 1, 0.16);\n  box-shadow: 0px 0px 2px #ff0303;\n  color: #ff0303;\n  transition: 0.5s;\n  cursor: pointer;\n}\n\n.alert-danger:hover {\n  background-color: rgba(220, 17, 1, 0.33);\n  transition: 0.5s;\n}\n\n.danger {\n  font-size: 18px;\n  color: #ff0303;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
