// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-blog-form {
  text-align: center;
  width: 100%;
  overflow: visible;
}

.custom-file {
  margin-top: 2rem;
}

.blog-create-textarea-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog-create-textarea-wrapper.purple-border textarea {
  color: black;
}

.improve-with-ai-btn {
  top: -0.5rem;
}
.improve-with-ai-btn > i {
  border: 1px solid black;
  padding: 5px 12px;
}
.improve-with-ai-btn:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}
.improve-with-ai-btn .custom-tooltip {
  visibility: hidden;
  top: 125%;
  background-color: gray;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
.improve-with-ai-btn .custom-tooltip:hover {
  opacity: 0;
  cursor: default;
}

.improve-with-ai-btn--category {
  top: calc(-105% - 0.25rem);
}`, "",{"version":3,"sources":["webpack://./src/components/blog/blog-create/BlogCreate.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,YAAA;AAAF;AAEE;EACE,uBAAA;EACA,iBAAA;AAAJ;AAII;EACE,mBAAA;EACA,UAAA;AAFN;AAME;EACE,kBAAA;EACA,SAAA;EACA,sBAAA;EACA,UAAA;EACA,mDAAA;AAJJ;AAMI;EACE,UAAA;EACA,eAAA;AAJN;;AASA;EACE,0BAAA;AANF","sourcesContent":[".create-blog-form {\n  text-align: center;\n  width: 100%;\n  overflow: visible;\n}\n\n.custom-file {\n  margin-top: 2rem;\n}\n\n.blog-create-textarea-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  &.purple-border textarea {\n    color: black;\n  }\n}\n\n.improve-with-ai-btn {\n  top: -0.5rem;\n\n  > i {\n    border: 1px solid black;\n    padding: 5px 12px;\n  }\n\n  &:hover {\n    .custom-tooltip {\n      visibility: visible;\n      opacity: 1;\n    }\n  }\n\n  .custom-tooltip {\n    visibility: hidden;\n    top: 125%;\n    background-color: gray;\n    opacity: 0;\n    transition: visibility 0s, opacity 0.3s ease-in-out;\n\n    &:hover {\n      opacity: 0;\n      cursor: default;\n    }\n  }\n}\n\n.improve-with-ai-btn--category {\n  top: calc(-105% + (-0.25rem));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
