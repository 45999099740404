// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-create {
  margin-top: 0;
}
.sub-create .sub-create-container {
  width: auto;
  padding: 5vh 0;
}
.sub-create .sub-create-container .sub-create-content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/courses/courses-single/courses-subsection-create/courses-subsection-create-form/CoursesSubsectionCreateForm.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,WAAA;EACA,cAAA;AACJ;AACI;EACE,UAAA;AACN","sourcesContent":[".sub-create {\n  margin-top: 0;\n\n  .sub-create-container {\n    width: auto;\n    padding: 5vh 0;\n\n    .sub-create-content {\n      padding: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
