// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-list-resource-control {
  display: flex;
  gap: 10px;
  align-items: center;
}
.course-list-resource-control > i {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/courses/courses-single/courses-list/courses-list-item/CoursesListItem.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[".course-list-resource-control {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n\n  > i {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
